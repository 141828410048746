<template>
  <div>
    <b-card>
      <div class="row text-center ">
        <div class="col-sm-12 col-md-3">
          <b-button to="/refund/cancel" variant="primary"
            >Initiate Refund</b-button
          >
        </div>
        <div class="col-sm-6 col-md-3">
          <b-form-datepicker
            v-model="startDate"
            :date-format-options="{
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            }"
            placeholder="start Date"
            block
            locale="en-US"
          />
        </div>
        <div class="col-sm-6 col-md-3">
          <b-form-datepicker
            v-model="endDate"
            :date-format-options="{
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            }"
            placeholder="end Date"
            block
            locale="en-US"
          />
        </div>
        <div class="col-sm-12 col-md-3">
          <b-form-group>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
          </b-form-group>
        </div>
      </div>
    </b-card>
    <b-card>
      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="items"
        :sticky-column="true"
        :isLoading="isDataLoading"
        @on-row-click="onRowClick"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Icon -->
          <span v-if="props.column.field === 'icon'" class="text-nowrap">
            <b-avatar
              :src="props.row.icon ? props.row.icon : ''"
              class="mx-1"
            />
          </span>

          <span v-else-if="props.column.field === '_id'">
            <router-link :to="`/refund/cancel?_id=${props.row._id}`">{{
              props.row._id
            }}</router-link>
          </span>

          <!-- Column -->
          <span
            v-else-if="
              props.column.field === 'status' ||
                props.column.field === 'paymentStatus'
            "
          >
            <b-badge
              :variant="statusBadgeVariant(props.row[props.column.field])"
            >
              {{ props.row[props.column.field] }}
            </b-badge>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10', '15']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BCard,
  BButton,
  BFormDatepicker,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import { getUserToken } from "@/auth/utils";
import axios from "axios";
import moment from "moment-timezone";

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BFormDatepicker,
  },
  data() {
    return {
      items: [],
      pageLength: 15,
      isDataLoading: true,
      startDate: moment()
        .subtract(3, "days")
        .toDate(),
      endDate: moment().toDate(),
      columns: [
        {
          label: "Transaction Id",
          field: "_id",
        },
        {
          label: "Payment Status",
          field: "paymentStatus",
        },
        {
          label: "Booking No",
          field: "bookingId",
        },
        {
          label: "Booking Status",
          field: "status",
        },
        {
          label: "Booking Date",
          field: "bookingDate",
        },
        {
          label: "CheckIn Date",
          field: "checkIn",
        },
        {
          label: "CheckOut Date",
          field: "checkOut",
        },
        {
          label: "Resort Name",
          field: "resortName",
        },
        {
          label: "Total Booking",
          field: "totalRoomRate",
        },

        {
          label: "Payment Gateway",
          field: "pg",
        },
        {
          label: "Payment ID",
          field: "paymentID",
        },
        {
          label: "Payment Mode",
          field: "paymentMode",
        },
        {
          label: "Remark",
          field: "remark",
        },
        {
          label: "Passenger Name",
          field: "passengerName",
        },
        {
          label: "Booked By",
          field: "role",
        },
        {
          label: "Booked By Name",
          field: "userName",
        },
        {
          label: "Booked By Email",
          field: "userEmail",
        },
        {
          label: "Customer Phone",
          field: "passengerPhone",
        },
        {
          label: "Refund remark",
          field: "refundRemark",
        },
      ],
      searchTerm: "",
    };
  },
  methods: {
    geTransactions() {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/reports/get-list/transactions`,
          {
            startDate: this.startDate,
            endDate: this.endDate,
            searchTerm: this.searchTerm,
          }
        )
        .then((response) => {
          console.log("response.data", response.data);
          this.items = response.data.bookingReports;
          this.isDataLoading = false;
        })
        .catch((error) => {
          console.log(error.response);
          this.isDataLoading = false;
        });
    },
    statusBadgeVariant(props) {
      switch (props) {
        case "Confirmed":
        case "Paid":
          return "light-success";
        case "Cancelled":
        case "Failed":
          return "light-danger";
        default:
          return "light-secondary";
      }
    },
    onSelectAll(params) {
      const selectedItems = params.selectedRows.map((item) => {
        return item._id;
      });
      this.itemsToTakeAction = selectedItems;
    },
    onRowClick(params) {
      const item = params.row._id;
      if (!params.selected) {
        const itemIndex = this.itemsToTakeAction.findIndex((itemObj) => {
          return itemObj == item;
        });
        if (itemIndex > -1) {
          this.itemsToTakeAction.splice(itemIndex, 1);
        }
      } else {
        this.itemsToTakeAction.push(item);
      }
    },
    handleWatch(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.geTransactions();
      }
    },
  },
  created() {
    this.geTransactions();
  },
  watch: {
    startDate: "handleWatch",
    endDate: "handleWatch",
    searchTerm: "handleWatch",
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.action-bar {
  display: flex;
}
</style>
